import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import SEO from '../../components/seo';
import Layout from '../../components/Layout';

import thumb from "../../images/walking.webp"

const Title = styled.h1`
  text-align: center;
  font-size: 40px;
`;

const Image = styled.img`
  max-height: 400px;
  min-width: 100%;
  height: auto;
  display: block;
`;

const Wrapper = styled.div`
  max-width: 720px;
  padding: 0 22px;
`;

const Subtitle = styled.h2`
  display: block;
  color: white;
  font-size: 22px;
  margin-top: 32px;
`;

const P = styled.p`
  color: white;
  font-size: 18px;
`;

const Button = styled(Link)`
  width: 220px;
  margin: 22px auto;
  text-align: center;
`;

function WalkingDenmark() {
  return (
    <>
      <SEO
        title="Vandreture i Danmark | Find events på Zooticket"
        description="Leder du efter en god vandretur i Danmark men er i tvivl om hvor? Se her 3 muligheder, som er velkendte vandreture i landet."
      />
      <Layout>
        <Wrapper className="event">
          <Title className="title">3 anbefalede vandreture i Danmark</Title>
          <Image
            width="500"
            height="500"
            className="event__image"
            src={thumb}
            alt="Vandretur i Danmark"
          />
          <Subtitle>Hærvejen</Subtitle>
          <P className="event__info_explore">
            Hærvejen skal næsten nævnes som den første vandretur. En vandretur
            der strækker sig flere hundrede kilometer ned gennem Jylland, som
            anbefales både til gang og også cykling. Det er muligt at starte fra
            Nordvestjylland, Nordøstjylland, Himmerland, Midtjylland og
            Sydjylland.
          </P>
          <Subtitle>Camønoen</Subtitle>
          <P className="event__info_explore">
            Camønoen er et velkendt vandretur i det Jyske, på godt og vel 175
            km. På turen kommer du i nærheden af strand, mark og
            grotteedderkoppen. En varm anbefaling, som er arrangeret af Museum
            Sydøst Danmark.
          </P>
          <Subtitle>Vesterhavscaminoen</Subtitle>
          <P className="event__info_explore">
            Sidste af de tre forslag - i en tilfældig rækkefølge, er
            Vesterhavscaminoen. Vesterhavscaminoen kan opleves med start fra
            Thorsminde, Agger og Skallerup. Varme anbefalinger til dig der vil
            opleve duften af saltvand og vind i håret.
          </P>
          <Subtitle>Er det din første ægte vandretur?</Subtitle>
          <P className="event__info_explore">
            For at få den bedst mulige oplevelse af vandreturen, er der en
            tjekliste vi anbefaler du går igennem. Læs mere inde på{' '}
            <a
              style={{ textDecoration: 'underline' }}
              className="event__info_explore"
              href="https://www.vandretips.dk"
              target="_blank"
              rel="noreferrer"
            >
              vandretips.dk
            </a>
            <br />
            <br />
            Vi ønsker dig den bedst mulige vandretur!
          </P>
        </Wrapper>
      </Layout>
    </>
  );
}

export default WalkingDenmark;
